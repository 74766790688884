import React, { Component } from 'react';
import StoreManager from '../appdata';

class StepBar extends Component{
  render() {
    const { indexStep } = StoreManager.appdata.thisApp.state
    var numberOfSteps = StoreManager.appdata.configEkyc.stepVerification.length
    var stepBarColor =  StoreManager.appdata.configEkyc.stepBarColor
    var stepBarBackgroundColor =  StoreManager.appdata.configEkyc.stepBarBackgroundColor

    var currentStep = indexStep
    console.log("currentStep" , currentStep, numberOfSteps)
    const steps = [];
    for (let i = 0; i < numberOfSteps; i++) {
      steps.push(
        <div
          key={i}
          style={{
            width: 50,
            height: 4,
            backgroundColor: i <= currentStep ? stepBarColor : stepBarBackgroundColor,
            marginRight: 5,
          }}
        ></div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem', // Tương ứng với 'mb-base'
        }}
      >
        {steps}
      </div>
    );
  }
}

export default StepBar;
