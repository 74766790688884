
import { v4 as uuidv4 } from 'uuid';

const SDK_Version = "3.3.15"

export default class StoreManager {
    static userdata = {
        platform: "web",
        os_version: "",
        app_version:SDK_Version,
        device_id: ""
    }
    
    

    static appdata = {
        defaultZoom: 1,
        media: null,
        thisApp: false,
        thisFront: false,
        thisBack: false,
        thisSelfie: false,
        accessToken: "",
        facetecProdKey: "",
        requestId: "",
        isMobile: false,
        rawImage: {
            front: "",
            back: "",
            selfie: ""
        },
        callback: () => { },

        configEkyc: {
            domain: "",
            configEndpoint: {
                front: "",
                back: "",
                selfie: "",
                complete: "",
                nfcqrverify: "",
                nfcrar: "",
                ocr: "",
                nfcbshield: "",
                createrequest: "",
                accesstoken: ""
            },
            clientId: "",
            clientSecret: "",
            configHeader: "",
            stepVerification: ["FRONT", "BACK", "SELFIE", "EDITOCR"],
            titleColor: "#091E42CC", // 80%
            subTitleColor: "#091E4299", // 60%
            closeColor: "#091E42CC", // 80%
            buttonCaptureColor: "#1182E7",
            titleButtonCaptureColor: "#ffffff",
            ovalColor: "#006cab", // 80%
            stepBarColor: "#006cab",
            stepBarBackgroundColor: "#D3D9E0",
            backgroundColor: "#ffffff",
            requestId: '',
            accessToken: '',
            maxRetry: 3
        },
        editOCR: {
            fullName: '',
            dateOfBirth: '',
            gender: '',
            idNumber: '',
            givenDate: '',
            placeOfIssue: '',
            permanentAddress: ''
        },
        sdkHistories:[]
    }
    static stopStream = () => {
        return new Promise((resolve) => {
            this.appdata.media?.getTracks().forEach(function (track) {
                track.stop();
            });
            // console.log("stopStream")

            // Sau khi tất cả track đã dừng, đặt media về null và resolve promise
            this.appdata.media = null;
            resolve();
        });
    }
    
    static getOSInfo = () => {
        const userAgent = window.navigator.userAgent;
        const platform = window.navigator.platform;
        let osName = 'Unknown OS';
        let osVersion = 'Unknown Version';
    
        if (/Mac/i.test(platform)) {
          osName = 'MacOS';
          const macVersion = userAgent.match(/Mac OS X (10[\.\_\d]+)/);
          if (macVersion) {
            osVersion = macVersion[1].replace(/_/g, '.');
          }
        } else if (/Win/i.test(platform)) {
          osName = 'Windows';
          const winVersion = userAgent.match(/Windows NT (\d+\.\d+)/);
          if (winVersion) {
            osVersion = winVersion[1];
            switch (osVersion) {
              case '10.0':
                osVersion = '10';
                break;
              case '6.3':
                osVersion = '8.1';
                break;
              case '6.2':
                osVersion = '8';
                break;
              case '6.1':
                osVersion = '7';
                break;
              default:
                osVersion = 'Unknown Version';
            }
          }
        } else if (/Android/i.test(userAgent)) {
          osName = 'Android';
          const androidVersion = userAgent.match(/Android (\d+(\.\d+)?)/);
          if (androidVersion) {
            osVersion = androidVersion[1];
          }
        } else if (/iOS/i.test(userAgent)) {
          osName = 'iOS';
          const iosVersion = userAgent.match(/OS (\d+)_\d+/);
          if (iosVersion) {
            osVersion = iosVersion[1];
          }
        } else if (/Linux/i.test(platform)) {
          osName = 'Linux';
        }
        return ` ${osName} _ ${osVersion}`
      };
    

    static loadMediaStream = () => {
        // console.log("loadMediaStream")
        if (this.appdata.media != null) {
            return Promise.resolve(this.appdata.media);
        } else {
            return new Promise((resolve, reject) => {
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.enumerateDevices) {
                    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // Kiểm tra Safari
                    this.appdata.isMobile = isMobile
                    const supports = navigator.mediaDevices.getSupportedConstraints();
                    console.log("supports", supports)
                    console.log("isMobile ", isMobile);
                    if (isMobile) {
                        navigator.mediaDevices.enumerateDevices()
                            .then(devices => {
                                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                                console.log("videoDevices", videoDevices)
    
                                const backCameras = videoDevices.filter(device => /back/i.test(device.label));
                                // var backCamera = backCameras.length > 0 ? backCameras[1] : null;
                                var backCamera = backCameras.length > 0 ? backCameras[backCameras.length - 1] : null;
                                console.log("backCameras", backCameras)
                                if (!backCamera && videoDevices.length > 0) {
                                    backCamera = videoDevices[0];
                                    console.log("Fallback camera", backCamera)
                                }
    
                                if (backCamera) {
                                    var constraints = {
                                        video: {
                                            ...(backCamera.deviceId ? { deviceId: { exact: backCamera.deviceId } } : {}),
                                            height: 800,
                                            width: 600,
                                            frameRate: { ideal: 30 },
                                            facingMode: "environment"
                                        }
                                    };
                                    if(isSafari) {
                                        constraints = {
                                            video: {
                                                height: 800,
                                                width: 600,
                                                frameRate: { ideal: 30 },
                                                facingMode: "environment"
                                            }
                                        }
                                    }
    
                                    return navigator.mediaDevices.getUserMedia(constraints);
                                } else {
                                    throw new Error('No back camera found');
                                }
                            })
                            .then(stream => {
                                this.appdata.media = stream;
                                const videoTrack = stream.getVideoTracks()[0];
                                const capabilities = videoTrack.getCapabilities();
                                // const settings = videoTrack.getSettings();
    
                                // Kiểm tra và áp dụng zoom nếu camera hỗ trợ
                                if (capabilities.zoom) {
                                    // let defaultZoom = settings.zoom || 1;
                                    // this.appdata.defaultZoom =  defaultZoom
                                    // let newZoom = defaultZoom * 1.5; // Tăng zoom thêm 1.5 lần
                                    
                                    videoTrack.applyConstraints({
                                        advanced: [{ zoom: 1.3 }]
                                    }).then(() => {
                                        // console.log('Zoom applied successfully to:');
                                    }).catch(error => {
                                        console.error('Failed to apply zoom:', error);
                                    });
                                }
                                
                                resolve(stream);
                            })
                            .catch(error => {
                                console.error("Error accessing the camera: ", error);
                                reject(error);
                            });
                    } else {
                        var videoObj = {
                            width: { ideal: 800 },
                            height: { ideal: 600 },
                            facingMode: "user",
                            frameRate: { ideal: 30 }
                        };
                        const constraints = { video: videoObj };
    
                        navigator.mediaDevices.getUserMedia(constraints)
                            .then(stream => {
                                this.appdata.media = stream;
                                resolve(stream);
                            })
                            .catch(error => {
                                console.error("Error accessing the camera: ", error);
                                reject(error);
                            });
                    }
                } else {
                    reject("Media devices not supported");
                }
            });
        }
    };
    static loadSelfieMediaStream = () => {
        return new Promise((resolve, reject) => {
            // Kiểm tra hỗ trợ MediaDevices và các phương thức cần thiết
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia || !navigator.mediaDevices.enumerateDevices) {
                console.error("Media devices or getUserMedia is not supported by this browser.");
                reject("Media devices or getUserMedia not supported.");
                return;
            }
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            this.appdata.isMobile = isMobile
            // Dừng stream hiện có (nếu có) để giải phóng camera
            if (this.appdata && this.appdata.media) {
                this.appdata.media.getTracks().forEach(track => track.stop());
                this.appdata.media = null;
            }
    
            // Kiểm tra các constraints được hỗ trợ
            const supports = navigator.mediaDevices.getSupportedConstraints();
            console.log("Supported constraints:", supports);
    
            // Thiết lập cấu hình video với facingMode "user"
            const videoObj = {
                width: { ideal: 800 },
                height: { ideal: 600 },
                advanced: [{ facingMode: "user" }]
            };
            const constraints = { video: videoObj };
    
            // Gọi getUserMedia để truy cập camera
            navigator.mediaDevices.getUserMedia(constraints)
                .then(stream => {
                    this.appdata.media = stream;
                    resolve(stream);
                })
                .catch(error => {
                    // Xử lý lỗi chi tiết để xác định nguyên nhân
                    if (error.name === "NotReadableError") {
                        console.error("Camera is in use by another application or failed to start:", error);
                    } else if (error.name === "OverconstrainedError") {
                        console.error("Camera constraints are not supported by this device:", error);
                    } else if (error.name === "NotAllowedError") {
                        console.error("Camera access was denied:", error);
                    } else {
                        console.error("An unexpected error occurred while accessing the camera:", error);
                    }
                    reject(error);
                });
        });
    };
    
    // static loadSelfieMediaStream = () => {
    //     // console.log("loadSelfieMediaStream")
    //     return new Promise((resolve, reject) => {
    //         if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.enumerateDevices) {
    //             const supports = navigator.mediaDevices.getSupportedConstraints();
    //             // console.log("supports", supports)
    //             var videoObj = {
    //                 width: { ideal: 800 },
    //                 height: { ideal: 600 },
    //                 facingMode: "user"
    //             };
    //             const constraints = { video: videoObj };

    //             navigator.mediaDevices.getUserMedia(constraints)
    //                 .then(stream => {
    //                     this.appdata.media = stream;
    //                     // console.log("resolve(stream);")
    //                     resolve(stream);
    //                 })
    //                 .catch(error => {
    //                     console.error("Error accessing the camera: ", error);
    //                     reject(error);
    //                 });
    //         } else {
    //             console.log("Media devices not supported")
    //             reject("Media devices not supported");
    //         }
    //     });
    // };
    static rootState = {
    }
    static async loadDataFromLocal() {
        var data = localStorage.getItem(`ekycuserdata_${SDK_Version}`)
        if (data) {
            this.userdata = JSON.parse(data)
            this.userdata.app_version = SDK_Version
        }else {
            let os = StoreManager.getOSInfo()
            this.userdata = {
                platform: "web",
                os_version: os,
                app_version: SDK_Version,
                device_id : uuidv4()
            } 
            this.updateDataToLocal()
        }
    }
    static updateDataToLocal() {
        localStorage.setItem(`ekycuserdata_${SDK_Version}`, JSON.stringify(this.userdata))
    }
    static resetState = () => {
        this.rootState = {
        }
        this.appdata = {
            defaultZoom: 1,
            media: null,
            thisApp: false,
            thisFront: false,
            thisBack: false,
            thisSelfie: false,
            accessToken: "",
            facetecProdKey: "",
            requestId: "",
            rawImage: {
                front: "",
                back: "",
                selfie: ""
            },
            callback: () => { },
    
            configEkyc: {
                domain: '/api',
                configEndpoint: {
                    front: "",
                    back: "",
                    selfie: "",
                    complete: "",
                    ocr: "",
                    createrequest: "",
                    accesstoken: ""
                },
                clientId: "",
                clientSecret: "",
                configHeader: "",
                stepVerification: ["FRONT", "BACK", "SELFIE", "EDITOCR"],
                titleColor: "#091E42CC", // 80%
                subTitleColor: "#091E4299", // 60%
                closeColor: "#091E42CC", // 80%
                buttonCaptureColor: "#1182E7",
                titleButtonCaptureColor: "#ffffff",
                ovalColor: "#006cab", // 80%
                backgroundColor: "#ffffff",
                requestId: '',
                accessToken: ''
            },
            editOCR: {
                fullName: '',
                dateOfBirth: '',
                gender: '',
                idNumber: '',
                givenDate: '',
                placeOfIssue: '',
                permanentAddress: ''
            },
            sdkHistories:[]
        }

    }
    
}
