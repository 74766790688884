import React from "react";
import StoreManager from "../appdata";
import { callAPISelfie2 } from "../api";
import Helpers, { screenSelfieState } from "../helpers";
import languageSDK from "../languages";
import TrueID from "../index";
import resource from "../resource";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FaceDetector, FilesetResolver } from "@mediapipe/tasks-vision";
import StepBar from "../components/StepBar";

export default class TrueIDActiveSelfieScreen extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();
        this.pointRef = React.createRef();
        this.animationRef = React.createRef();
        this.canvasCaptureRef = React.createRef();

        this.state = {
            width: window.innerWidth - 20,
            selfieState: screenSelfieState.START,
            canvasWidth: 300,
            canvasHeight: 400,
            errorMessage: '',
            faceInOval: false,
            selfie1Base64: '',
            selfie2Base64: '',
            ovalExpanded: false,
            angle: 0,
            radiusX: 100,
            radiusY: 140,
            centerX: 150,
            centerY: 200,
            borderColor: 'transparent'
        };
        this.faceStableTimeout = null;
    }

    async componentDidMount() {
        StoreManager.appdata.thisSelfie = this;
        Helpers.loading.drawLoadingSpinner();

        await StoreManager.stopStream();

        try {
            await StoreManager.loadSelfieMediaStream();
            this.setState({ selfieState: screenSelfieState.CAMERA_SETUP });
        } catch (error) {
            console.error("Failed to load media stream:", error);
            return;
        }

        if (StoreManager.appdata.media) {
            try {
                this.videoRef.current.srcObject = StoreManager.appdata.media;
                // Helpers.loading.hideLoadingSpinner();
                this.setState({ borderColor: StoreManager.appdata.configEkyc.ovalColor });

                this.videoRef.current.onloadedmetadata = () => {
                    this.updateCanvasSize();
                };
            } catch (error) {
                console.log("Error setting video source or hiding spinner:", error);
            }
        } else {
            console.error("Media stream not available");
        }

        setTimeout(() => {
            try {
                this.initFaceDetection();
            } catch (error) {
                console.log("Error initializing face detection:", error);
            }
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_DEFAULT });
        }, 7000);

        window.addEventListener('resize', this.updateDimensions);

        if (this.pointRef.current) {
            this.movePoint();
        }

    }

    updateCanvasSize = () => {
        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
        const canvasCapture = this.canvasCaptureRef.current;

        if (video && video.videoWidth && video.videoHeight) {
            // const videoAspectRatio = video.videoWidth / video.videoHeight;
            const canvasWidth =  300;
            const canvasHeight = 400;
    
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            canvasCapture.width = canvasWidth
            canvasCapture.height = canvasHeight
    
            console.log("Updated Canvas size:", canvas.width, canvas.height);
            this.setState({ canvasHeight });
        }
    };
    
    
    

    initFaceDetection = async () => {
        try {
            const vision = await FilesetResolver.forVisionTasks(
                "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm"
            );

            this.faceDetector = await FaceDetector.createFromOptions(vision, {
                baseOptions: {
                    modelAssetPath: 'https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite',
                },
                runningMode: 'VIDEO',
                minDetectionConfidence: 0.5
            });

            const processFrame = async () => {
                if (this.videoRef.current && this.videoRef.current.srcObject) {
                    const results = await this.faceDetector.detectForVideo(this.videoRef.current, performance.now());
                    this.onFaceDetectionResults(results);
                } else {
                    return;
                }
                this.requestId = requestAnimationFrame(processFrame);
            };

            this.requestId = requestAnimationFrame(processFrame);

        } catch (error) {
            console.error("Error initializing face detection:", error);
        }
    };

    // Cập nhật phương thức onFaceDetectionResults để vẽ bounding box
    onFaceDetectionResults = (results) => {
        const { selfieState } = this.state;
    
        // Kiểm tra trạng thái
        if (
            selfieState.value === screenSelfieState.CALLING_API.value ||
            selfieState.value === screenSelfieState.CALL_API_FAILED.value
        ) {
            // console.log("Skipping detection due to selfieState:", selfieState);
            return;
        }
    
        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
    
     
    
        // Kiểm tra kết quả phát hiện khuôn mặt
        if (results && results.detections.length > 0) {
    
            const detection = results.detections[0];
            const boundingBox = detection.boundingBox;
    
            // Log bounding box
    
            const faceInOval = this.isFaceInOval(boundingBox);
            const faceToOvalRatioValid = this.checkFaceToOvalRatio(boundingBox, video.offsetWidth);
    
            console.log("Face in oval:", faceInOval, "Face to oval ratio valid:", faceToOvalRatioValid);
    
            if (faceInOval && faceToOvalRatioValid) {
                if (!this.state.faceInOval) {
                    this.setState({ faceInOval: true });
                    this.showKeepStableMessage();
                }
            } else {
                this.setState({ faceInOval: false });
                clearTimeout(this.faceStableTimeout);
            }
        } else {
            this.setState({ faceInOval: false , selfieState: screenSelfieState.FACE_DETECT_DEFAULT});

            console.log("No detections found.");
        }
    };
    
    

    movePoint = () => {
        let angle = 0;
        const numberOfPoints = 40;
        const pointsPath1 = [];
        const pointsPath2 = [];

        for (let i = 0; i < numberOfPoints; i++) {
            const point1 = document.createElement('div');
            point1.style.position = 'absolute';
            point1.style.width = '4px';
            point1.style.height = '4px';
            point1.style.zIndex = 1000 + i;
            point1.style.backgroundColor = StoreManager.appdata.configEkyc.ovalColor;
            point1.style.borderRadius = '50%';
            point1.style.display = 'none';
            this.animationRef.current.appendChild(point1);
            pointsPath1.push(point1);
        }

        for (let i = 0; i < numberOfPoints; i++) {
            const point2 = document.createElement('div');
            point2.style.position = 'absolute';
            point2.style.width = '4px';
            point2.style.height = '4px';
            point2.style.zIndex = 1000 + i;
            point2.style.backgroundColor = StoreManager.appdata.configEkyc.ovalColor;
            point2.style.borderRadius = '50%';
            point2.style.display = 'none';
            this.animationRef.current.appendChild(point2);
            pointsPath2.push(point2);
        }

        this.pointMovementInterval = setInterval(() => {
            const { ovalExpanded, selfieState } = this.state;

            if (selfieState.value === 7) {
                pointsPath1.forEach(point => point.style.display = 'block');
                pointsPath2.forEach(point => point.style.display = 'block');
            } else {
                pointsPath1.forEach(point => point.style.display = 'none');
                pointsPath2.forEach(point => point.style.display = 'none');
                return;
            }

            angle += 0.05;

            const videoWidth = this.animationRef.current.offsetWidth;
            const videoHeight = this.animationRef.current.offsetHeight;

            const centerX = videoWidth / 2 - 2;
            const centerY = videoHeight / 2 - 2;

            const radiusX = videoWidth * (ovalExpanded ? 0.45 : 0.35) * 0.9;
            const radiusY = videoHeight * (ovalExpanded ? 0.45 : 0.35) * 0.9;

            pointsPath1.forEach((point, index) => {
                const pointAngle = angle - index * 0.03;
                const x = centerX + radiusX * Math.cos(pointAngle);
                const y = centerY + radiusY * Math.sin(pointAngle);

                point.style.left = `${x}px`;
                point.style.top = `${y}px`;
            });

            pointsPath2.forEach((point, index) => {
                const pointAngle = angle - index * 0.03 + Math.PI;
                const x = centerX + radiusX * Math.cos(pointAngle);
                const y = centerY + radiusY * Math.sin(pointAngle);

                point.style.left = `${x}px`;
                point.style.top = `${y}px`;
            });
        }, 20);
    }

    componentWillUnmount() {
        clearInterval(this.pointMovementInterval);
        window.removeEventListener('resize', this.updateDimensions);

        if (document.getElementById('loadingCanvas')) {
            Helpers.loading.hideLoadingSpinner();
        }
    }

    updateDimensions = () => {
        // const height = window.innerWidth - 20;
        // const width = height * 0.75;
        // this.setState({
        //     width: width,
        //     canvasWidth: width,
        //     canvasHeight: height,
        // });
    };


checkFaceToOvalRatio = (boundingBox, videoWidth) => {
    const { ovalExpanded, selfieState } = this.state;
    const canvas = this.canvasRef.current;
    const video = this.videoRef.current;


    const { originX, originY, width, height } = boundingBox;

    let scale = canvas.width / video.videoHeight;
    if(StoreManager.appdata.isMobile){
        scale = canvas.width / video.videoWidth;
    }


    const faceWidth = width * scale;
    const ovalWidth = canvas.width //* (ovalExpanded ? 0.8 : 0.7);

    const faceToOvalRatio = faceWidth / ovalWidth;

    console.log("faceToOvalRatio", faceToOvalRatio)

    if (faceToOvalRatio < (ovalExpanded ? 0.6 : 0.5)) {
        if (selfieState !== screenSelfieState.FACE_DETECT_SMALL) {
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_SMALL });
        }
        return false;
    } else if (faceToOvalRatio > (ovalExpanded ? 0.8 : 0.8)) {
        if (selfieState !== screenSelfieState.FACE_DETECT_LARGE) {
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_LARGE });
        }
        return false;
    } else {
        if (selfieState !== screenSelfieState.FACE_DETECT_OK) {
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_OK });
        }
        return true;
    }
};



    isFaceInOval = (bbox) => {
        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
    
        if (!video || !canvas) {
            console.error("Video hoặc canvas không được tham chiếu đúng");
            return false;
        }
    
        let scale = canvas.width / video.videoHeight;
        if(StoreManager.appdata.isMobile){
            scale = canvas.width / video.videoWidth;
            
        }



        let { originX, originY, width, height } = bbox;

        // Xử lý lật bounding box nếu video bị lật ngang
        originX = video.videoWidth - originX - width;
    
        // Tính tọa độ và kích thước bounding box theo tỉ lệ
        const x = originX * scale;
        const y = originY * 0.375;
        const boxWidth = width * scale;
        const boxHeight = height * scale;
    
        // Xác định tâm của bounding box
        const faceCenter = {
            x: x + boxWidth / 2,
            y: y + boxHeight / 2,
        };
    
        // Xác định tâm của canvas
        const videoCenter = {
            x: canvas.width / 2,
            y: canvas.height / 2,
        };
    
        // Tính khoảng cách giữa tâm khuôn mặt và trung tâm canvas
        const dx = faceCenter.x - videoCenter.x;
        const dy = faceCenter.y - videoCenter.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
    
        const maxDistance = this.state.ovalExpanded ? 130 : 80;
        console.log("maxDistance",maxDistance , distance)



        // // Draw face bbox
        // const context = canvas.getContext("2d");
        // context.clearRect(0, 0, canvas.width, canvas.height); // Xóa canvas trước khi vẽ
        // context.save();
    
        // context.strokeStyle = "blue"; // Màu của bounding box
        // context.lineWidth = 3; // Độ dày của đường viền
        // context.strokeRect(x, y, boxWidth, boxHeight); // Vẽ bounding box
    
        // context.beginPath();
        // context.arc(faceCenter.x, faceCenter.y, 5, 0, 2 * Math.PI);
        // context.fillStyle = "red"; // Màu của tâm
        // context.fill();
        // context.restore();


    
    
        return distance < maxDistance;
    };
    
    



    showKeepStableMessage = () => {
        this.faceStableTimeout = setTimeout(() => {
            this.capture();
        }, 1500);
    };

    capture = () => {
        let { faceInOval, selfieState } = this.state;
        if (!faceInOval) {
            return;
        }
        if (selfieState.value !== screenSelfieState.FACE_DETECT_OK.value) {
            return;
        }

        const canvas = this.canvasCaptureRef.current;
        const video = this.videoRef.current;

        if (canvas && video) {
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;

            const currentRatio = videoWidth / videoHeight;
            const targetRatio = 3 / 4;

            let targetWidth, targetHeight;
            let offsetX = 0, offsetY = 0;

            if (currentRatio > targetRatio) {
                targetHeight = videoHeight;
                targetWidth = videoHeight * targetRatio;
                offsetX = (videoWidth - targetWidth) / 2;
            } else {
                targetWidth = videoWidth;
                targetHeight = videoWidth / targetRatio;
                offsetY = (videoHeight - targetHeight) / 2;
            }

            canvas.width = targetWidth;
            canvas.height = targetHeight;

            const context = canvas.getContext('2d');
            context.drawImage(
                video,
                offsetX, offsetY, targetWidth, targetHeight,
                0, 0, targetWidth, targetHeight
            );

            const imageSrc = canvas.toDataURL('image/jpeg');

            if (!this.state.ovalExpanded) {
                this.setState({
                    selfie1Base64: imageSrc.replace('data:image/jpeg;base64,', ''),
                    ovalExpanded: true,
                    faceInOval: false,
                    selfieState: screenSelfieState.FACE_DETECT_DEFAULT,
                });

                this.setState({
                    canvasWidth: this.state.canvasWidth * 1.3,
                    canvasHeight: this.state.canvasHeight * 1.3
                });

            } else {
                this.setState({
                    selfie2Base64: imageSrc.replace('data:image/jpeg;base64,', '')
                }, () => {
                    if (this.state.selfieState.value > 0) {
                        Helpers.loading.drawLoadingSpinner();
                        callAPISelfie2(this.state.selfie1Base64, this.state.selfie2Base64);
                        this.setState({
                            selfieState: screenSelfieState.CALLING_API
                        });
                    }
                });
            }
        }
    };

    updateSuccess = () => {
        if (this.videoRef.current && this.videoRef.current.srcObject) {
            const stream = this.videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            this.videoRef.current.srcObject = null;
        }

        this.setState({ selfieState: screenSelfieState.UPDATE_SUCCESS }, () => {
            StoreManager.appdata.thisApp.nextStep();
        });
    };

    updateError = () => {
        this.setState({ selfieState: screenSelfieState.CALL_API_FAILED });
    };

    actionButton = () => {
        this.setState({
            canvasWidth: 300,
            canvasHeight: 400,
            selfieState: screenSelfieState.START, ovalExpanded: false,
            faceInOval: false
        });

        setTimeout(() => {
            this.setState({ selfieState: screenSelfieState.FACE_DETECT_DEFAULT });
        }, 1500);
    };

    closeScreen = () => {
        if (this.videoRef.current && this.videoRef.current.srcObject) {
            const stream = this.videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            this.videoRef.current.srcObject = null;
        }

        StoreManager.appdata.callback({ code: 0, errorMessage: "User Close SDK" });
        TrueID.close();
    };


    render() {
        const { width, selfieState, canvasWidth, canvasHeight, faceInOval, ovalExpanded, borderColor } = this.state;
        let configEkyc = StoreManager.appdata.configEkyc;
        const subTitle = selfieState.message;
        const title = `${languageSDK.language.trueID_selfie_photo}`

        const buttonTitle = selfieState === screenSelfieState.CALL_API_FAILED ? "Thử lại" : `${languageSDK.language.trueID_take_photo}`;

        const clipPathSize = ovalExpanded ? 'ellipse(45% 45% at 50% 50%)' : 'ellipse(35% 35% at 50% 50%)';

        return (
            <PerfectScrollbar>
                <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', position: 'relative', padding: '10px' }}>
               
                    <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <a onClick={this.closeScreen} style={{ fontSize: '16px', color: configEkyc.closeColor, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                            X
                        </a>
                    </div>

                    <div style={{ display: selfieState === screenSelfieState.CALLING_API || selfieState === screenSelfieState.CAMERA_SETUP ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
                        <canvas id="loadingCanvas" width="50" height="50"></canvas>
                    </div>

                    <p style={{ color: configEkyc.titleColor, fontSize: 20, marginBottom: '20px', fontWeight: 600 }}>{title}</p>
                    <StepBar />

                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                        <div
                            style={{
                                width: width,
                                height: width,
                                maxWidth: 300,
                                maxHeight: 400,
                                overflow: 'hidden',
                                position: 'relative'
                            }}>
                            
                            <div ref={this.animationRef} style={{
                                width: 'calc(100%)',
                                height: 'calc(100%)',
                                position: 'absolute',
                                backgroundColor: 'transparent'
                            }}></div>
                           <div style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                top: 0,
                                boxSizing: 'border-box',
                                display: selfieState != screenSelfieState.UPDATE_SUCCESS ? 'inline-block' : 'none',
                                backgroundColor: borderColor,
                                clipPath: clipPathSize,
                            }}></div> 

                           
                            <video ref={this.videoRef} autoPlay playsInline webkit-playsinline muted style={{
                                width: 'calc(100% - 12px)',
                                height: 'calc(100% - 12px)',
                                objectFit: 'cover',
                                transform: 'scaleX(-1)',
                                clipPath: clipPathSize,
                                position: 'absolute',
                                top: '6px',
                                left: '6px',
                            }}></video>
                            <div style={{
                                position: 'absolute',
                                top: ovalExpanded ? '15%' : '20%',
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                                backgroundColor: configEkyc.ovalColor, // Màu xanh dương giống ảnh
                                color: 'white', // Chữ màu trắng
                                padding: '10px 20px',
                                borderRadius: '10px',
                                fontSize: 18,
                                fontWeight: 600,
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Đổ bóng nhẹ
                                textAlign: 'center',
                                whiteSpace: 'nowrap', // Không cho text xuống dòng
                                overflow: 'hidden', // Ẩn phần text tràn ra
                                textOverflow: 'ellipsis', // Thêm "..." nếu text quá dài
                            }}>
                                {subTitle}
                            </div>
                            <canvas
                                ref={this.canvasCaptureRef}
                                style={{ 
                                    position: 'absolute',
                                    display:'none', 
                                    top: 0, 
                                    left: 0, 
                                    width: '100%', 
                                    height: '100%'  // Đảm bảo canvas chiếm toàn bộ không gian video
                                }}
                            />
                            <canvas
                                ref={this.canvasRef}
                                style={{ 
                                    position: 'absolute', 
                                    top: 0, 
                                    left: 0, 
                                    width: '100%', 
                                    height: '100%'  // Đảm bảo canvas chiếm toàn bộ không gian video
                                }}
                            />
                            
                            <div ref={this.pointRef} style={{
                                position: 'absolute',
                                width: '4px',
                                height: '4px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                            }}></div>
                        </div>
                    </div>
                    {/* <p style={{ color: configEkyc.subTitleColor, fontSize: 18, marginBottom: '20px' }}>{subTitle}</p> */}


                    {selfieState === screenSelfieState.CALL_API_FAILED && (
                        <button onClick={this.actionButton} style={{ marginTop: '40px', width: width ,maxWidth: 600, fontSize: '18px', padding: '10px 20px', borderRadius: '5px', backgroundColor: configEkyc.buttonCaptureColor, color: configEkyc.titleButtonCaptureColor, border: 'none', cursor: 'pointer' }}>
                            {buttonTitle}
                        </button>
                    )}
                </div>
            </PerfectScrollbar>
        );
    }
}
